import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import _ from "lodash"
import { device } from "../styles/breakPoints"

const FooterContainer = styled.footer`
    padding: 20px;
    margin-top: 6rem;
    border-width: 1px 0 0 0;
    border-style: dotted;
    border-color: var(--color-purple);
    background-color: var(--color-white-1);
`
const FooterLogo = styled.div`
    h2 {
        color: var(--color-black-1);
        font-size: 4rem;
    }
`

const FooterSections = styled.div`
    display: flex;
    max-width: 1160px;
    margin: 0 auto 20px auto;
    border-bottom: 1px solid #f0f0ff;
    div:nth-child(1) {
        flex-grow: 2;
    }
    div:nth-child(2),
    div:nth-child(3),
    div:nth-child(4) {
        flex-grow: 1;
    }
    &>div:not(:first-child) {
        margin-left: 2rem;
    }
    &>div {
        h3 {
            color: var(--color-black-1);
            font-size: 2.2rem;
            span {
                z-index: 101;
                position: relative;
            }
            &::after {
                content: "";
                height: 1.2rem;
                background-color: var(--color-secondary);
                opacity: 0.5;
                display: block;
                position: relative;
                z-index: 100;
                top: -1.2rem;
            }
        }
    }
    @media ${device.mobile} {
        flex-direction: column;
    }
`

const FooterCategories = styled.div`
    ul {
        margin-left: 0;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        max-width: 500px;
        li {
            border: 1px solid #c5c5c5;
            border-radius: 1000px;
            padding: 0px 20px;
            margin-right: 10px;
            font-size: 1.4rem;
        }
    }
`

const FooterCurrentRead = styled.div`
    display: flex;
    flex-direction: column;
`

const FooterSubscribe = styled.div`
    display: flex;
    flex-direction: column;
`

const FooterCaption = styled.div`
    font-size: 1.2rem;
    text-align: center;
`

const Footer = ({data}) => {
    const categoriesList = [];
    data.allMarkdownRemark.edges.forEach(edge => {
        edge.node.frontmatter.categories.forEach(category => {
            if (!categoriesList.includes(category) 
                && categoriesList.length < 10
                && category !== "") {
                categoriesList.push(<Link to={`/categories/${_.kebabCase(category)}`}><li key={category}>{category}</li></Link>);
            }
        });
    });
    return (
        <FooterContainer>
            <FooterSections>
                <FooterLogo>
                    <h2>tn.</h2>
                </FooterLogo>
                <FooterCategories>
                    <h3>
                        <span>Blog categories</span>
                    </h3>
                    <ul>
                        {categoriesList}
                    </ul>
                </FooterCategories>
                <FooterSubscribe>
                    <h3>
                        <span>Subscribe via Email</span>
                    </h3>
                    <label>Enter your email address to subscribe.</label>
                    <input></input>
                </FooterSubscribe>
                <FooterCurrentRead>
                    <h3>
                        <span>Currently Reading</span>
                    </h3>
                    <em><p>Henna Artist by Alka Joshi</p></em>
                </FooterCurrentRead>
            </FooterSections>
            <FooterCaption>
                © {new Date().getFullYear()} {data.site.siteMetadata.title} - Built by
                {` `}
                <a className="footer-gatsby" href="/">Reemuu</a>
            </FooterCaption>
        </FooterContainer>
    )
}

export default function StaticQueryFooter(props) {
    return (
        <StaticQuery 
        query={graphql`
            query FooterQuery {
                site {
                    siteMetadata {
                        title
                    }
                }
                allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "blog"}}}) {
                    edges {
                        node {
                            frontmatter {
                                categories
                            }
                        }
                    }
                }
            }
        `}
        render={ data => <Footer data={data} {...props} />}
        />
    )
}

Footer.propTypes = {
    data: PropTypes.shape({
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                title: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
}
