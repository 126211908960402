import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { ThemeToggler } from "gatsby-plugin-dark-mode"
import { FaFacebookF, FaTwitter, FaInstagram, FaRegSun, FaRegMoon, FaBars, FaTimes} from "react-icons/fa"
import { device } from "../styles/breakPoints";
import LogoBlack from "../../content/assets/tenzinnoryang-logo-black.inline.svg";

const NavbarContainer = styled.header`
    top: -50px;
    transition: top 400ms ease;
    backdrop-filter: blur(6px); 
    background-color: var(--color-navbar-bg);
    height: 80px;
    &.scrolled {
        position: fixed;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 999;
    }
    @media ${device.tablet} {
      .header-right {
        display: none;
        opacity: 0;
        left: 0;
        position: fixed;
        transition: opacity 500ms;
        height: 100vh;
        width: 100%;
        background-color: var(--color-navbar-bg);
        backdrop-filter: blur(6px); 
        top: 120px;
        text-align: center;
        nav.nav-container {
          justify-content: center;
        }
        ul {
          list-style: none;
          flex-flow: column;
          &.header-social {
            flex-flow: row;
            justify-content: center;
            li {
              svg {
                width: 14px;
                height: 14px;
              }
            }
          }
        }
      }
      &.menu-open {
        .header-right {
          display: block;
          opacity: 1;
        }
      }
    }
    .header-right {
      display: flex;
      justify-content: flex-end;
      flex-grow: 1;
      width: 50%;
      font-family: 'Source Serif Pro', 'Helvetica', serif;
      ul {
        &.header-nav {
          display: flex;
          align-items: center;
          font-size: 1.8rem;
          li {
            margin-bottom: inherit;
          }
        }
      }
      @media ${device.tablet} {
        display: none;
      }
    }
    .header-left {
      display: flex;
      flex-grow: 1;
      width: 50%;
      .header-logo {
        position: absolute;
        top: 0px;
      }
      @media ${device.tablet} {
        justify-content: flex-end;
        width: 60%;
      }
    }
    &.menu-open.scrolled {
      .header-right {
        top: 80px;
      }
    }
`

const Logo = styled(Link)`
  span {
    margin: 0;
    color: var(--color-black);
    font-family: 'Source Serif Pro', serif;
    font-weight: 400;
    font-size: 2.4rem;
  }
  svg {
    width: 100%;
    max-width: 200px;
    height: 100%;
  }
`

const MobileButtonContainer = styled.div`
  display: none;
  width: 40%;
  justify-content: flex-end;
  @media ${device.tablet} {
    display: flex;
  }
  button {
    border: none;
    background: none;
  }
`

const Navbar = () => {
    const [scrolled, setScrolled] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 200) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    }

    const handleMenu = () => {
      setMenuOpen(!menuOpen);
      console.log('menuOpen', menuOpen);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    });

    let navbarClasses = ['navbar'];

    let menuClasses = ['menu'];

    if (scrolled) {
        navbarClasses.push('scrolled');
    }

    if (menuOpen) {
      menuClasses.push('open');
    }

    const toggler = (
        <div className="toggler">
          <ThemeToggler>{({ theme, toggleTheme }) => (
            <label className="tog">
              <input
                type="checkbox"
                onChange={e =>
                  toggleTheme(e.target.checked ? "dark" : "light")
                }
                checked={theme === "dark"}
                className="tog-checkbox"
              />
              {theme === "dark" ? (
                <div className="tog-text">
                  <FaRegSun />
                </div>
              ) : (
                <div className="tog-text">
                  <FaRegMoon />
                </div>
              )}
            </label>
          )}</ThemeToggler>
        </div>
    );

    const MobileButton = (
      <button
        onClick={handleMenu}>
        {menuOpen === true ? <FaTimes /> : <FaBars />}
      </button>
    )

    return (
        <NavbarContainer className={`header-container ${navbarClasses.join(" ")} ${menuClasses.join("-")}`}>
            <div className="header-wrapper">
              <div className="header-left">
                  <Logo
                  className="header-logo"
                  to={`/`}
                  >
                    <LogoBlack />
                  </Logo>
              </div>
              <div className="header-right">
                  <nav className="nav-container">
                  <ul className="header-nav">
                      <li id="header-nav-first"><Link to={`/about`}>About</Link></li>
                      <li><Link to={`/categories`}>Categories</Link></li>
                      <li><Link to={`/search`}>Search</Link></li>
                      <li className="theme-toggler-nav">{toggler}</li>
                  </ul>
                  </nav>
                  {/* <ul className="header-social">
                    <li><FaFacebookF/></li>
                    <li><FaTwitter /></li>
                    <li><FaInstagram /></li>
                    <li className="theme-toggler-nav">{toggler}</li>
                  </ul> */}
              </div>
              <MobileButtonContainer>
                {MobileButton}
              </MobileButtonContainer>
            </div>
        </NavbarContainer>
    );
};

export default Navbar;