import { createGlobalStyle } from "styled-components"
const Normalize = createGlobalStyle`
    html {
        font-size: 10px;
    }
    body {
        font-size: 14px;
        font-size: 1.6rem;
        background-color: var(--color-bg);
        color: var(--color-font);
        caret-color: var(--color-font);
        transition: color, background-color 0.5s ease 0s;
        letter-spacing: -0.0125em;
        margin: 0;
    }
    ::selection {
        background: var(--color-teal);
    }
    p {
        line-height: 1.5em;
    }
    hr {
        border: 0em;
        height: 0.0625rem;
        background-color: var(--color-gray-2);
    }
    h1 {
        font-size: 2.5rem;
        margin-bottom: 0em;
    }
    h2 {
        font-size: 2.25rem;
        margin-bottom: 0em;
    }
    h3 {
        font-size: 2rem;
        margin-bottom: 0em;
    } 
    h4 {
        font-size: 1.75rem;
        margin-bottom: 0em;
    }
    h5 {
        font-size: 1.5rem;
        margin-bottom: 0em;
    }
    h6 {
        font-size: 1.25rem;
        margin-bottom: 0em;
    }
    table {
        width: 100%;
        max-width: 44rem;
        border-collapse: collapse;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
    thead {
        border-bottom: 0.0625rem;
        border-bottom-style: solid;
        border-color: var(--color-gray-4);
    }
    tfoot {
        border-top: 0.0625rem;
        border-top-style: solid;
        border-color: var(--color-gray-4);
    }
    th, td {
        padding: 0.875rem;
    }
    ol {
        padding-inline-start: 2.25em;
        margin-block-start: 1.25em;
        margin-block-end: 1.25em;
    }
    ul {
        padding-inline-start: 2.25em;
        margin-block-start: 1.25em;
        margin-block-end: 1.25em;
    }
    li {
        line-height: 2em;
    }
    blockquote {
        border-left: 0.25em solid var(--color-gray-3);
        padding: 0em 0.75em;
        font-style: italic;
        margin-left: 2.25em;
        margin-right: 2.25em;
    }
    pre {
        background-color: black;
        border-radius: 0.75em;
        padding: 1.5em 2.5em;
        line-height: 2.5em;
        font-size: 0.875em;
        /* border-style: solid; */
        /* border-width: 0.0625em; */
        /* border-color: var(--color-gray-5); */
    }
    code {
        background-color: black;
        color: rgba(255, 255, 255, 0.9);
        border-radius: 0.25em;
        padding: 0em 0.1875em;
    }
    pre code{
        padding: 0em;
    }

    @media(max-width: 900px) {
        h1 {
            font-size: 1.8rem;
        }
    }
`

export default Normalize;