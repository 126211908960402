const size = {
    mobile: '600px',
    tablet: '900px',
    desktop: '1100px'
}

export const device = {
    mobile: `(max-width: ${size.mobile})`,
    tablet: `(max-width: ${size.tablet})`,
    desktop: `(max-width: ${size.desktop}`
}