import { createGlobalStyle } from "styled-components"
import logoBlack from "../../content/assets/tenzinnoryang-logo-black.inline.svg"
import logoWhite from "../../content/assets/tenzinnoryang-logo-white.inline.svg"
import { device } from "./breakPoints"


const GlobalStyle = createGlobalStyle`
    /* Layout */
    .contour {
        border: 0em;
        height: 0.0625rem;
        background-color: var(--color-gray-2);
    }

    .site-container {
        border-top: 8px solid var(--color-secondary);
    }

    /* Layout-Header */
    .header-container {
        display: flex;
        align-items: baseline;
    }

    .header-container a {
        text-decoration: none;
        color: inherit;
    }

    .header-container div::selection,
    .header-container a::selection {
        background: transparent;
    }

    .header-container .header-wrapper {
        padding-left: 40px;
        padding-right: 40px;
        margin: 0px auto;
        height: inherit;
        width: 100%;
        max-width: 1200px;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        align-items: center;
    }

    .nav-container {
        font-size: 0.875em;
    }

    .header-nav,
    .header-social {
        list-style: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0em;
        margin: 0.25rem 0em;
    }

    .header-nav li {
        margin-left: 1.5em;
        font-size: 1em;
    }

    .header-nav a:hover {
        color: var(--color-purple);
    }

    .header-social li {
        display: flex;
        margin: 0 0 0 1.5rem;
    }

    .header-social li svg:hover {
        fill: var(--color-purple);
    }

    .tog-checkbox {
        display: none;
    }
    .tog-text {
        cursor: pointer;
    }
    /* Layout - Main */
    main {
        margin: 0px auto;
        /* max-width: 1140px; */
        /* padding: 0px 70px; */
    }

    main article.post-container {
        max-width: 700px;
        margin: 0px auto;
    }
    /* Layout-Footer */
    .footer-copyright {
        text-align: center;
        font-size: 0.875em;
    }

    .footer-gatsby {
        text-decoration: none;
        color: inherit;
    }

    /* Index - Posts Wrapper */

    .masonry-posts-wrapper {
        display: -webkit-box; /* Not needed if autoprefixing */
        display: -ms-flexbox; /* Not needed if autoprefixing */
        display: flex;
        margin-left: -30px; /* gutter size offset */
        width: auto;    
    }

    .masonry-post-column {
        padding-left: 30px; /* gutter size */
        background-clip: padding-box;
    }

    @media(min-width: 600px) {
        .posts-wrapper {
            grid-template-columns: repeat(2, minmax(250px, 1fr));
        }      
    }
    @media(min-width: 900px) {
        .posts-wrapper {
            grid-template-columns: repeat(3, minmax(250px, 1fr));
        }  
    }
    /* Postcard*/

    .post-header {
        text-align: center;
    }
    .post-header h1 {
        font-size: 3em;
        letter-spacing: -0.025em;
        margin-top: 0.8125em;
        margin-bottom: 0em;
        
    }
    .post-header p {
        font-size: 0.8125em;
        margin-top: 0.8125em;
    }

    .search-box-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .search-box {
        margin: 2em auto;
        width: 35%;
        border-radius: 0.625em;
        border: 0.0625em solid var(--color-font);
        box-shadow: 0 0.0625em 0.25em var(--color-postcard);
        padding: 0.8125em;
        color: inherit;
        background-color: transparent;
        
    }
    .search-box:focus {
        outline: none;
    }
    .search-box::placeholder {
        color: var(--color-gray-1);
    }

    .tag-archive-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .tag-archive-item {
        border: 0.0625em solid var(--color-postcard);
        border-radius: 0.3em;
        box-shadow: 0 0.0625em 0.25em var(--color-postcard);
        margin: 0.5em;
        padding: 0.2em 0.6em;
        
    }
    .tag-archive-link {
        text-decoration: none;
        color: inherit;
    }

    .pagenator {
        display: flex;
        justify-content: center;
        margin-bottom: 1em;
        margin-top: 1em;
        font-size: 0.875em;
    }
    .pagenator-link svg {
        fill: var(--color-font);
    }
    .pagenator-unlinked svg {
        fill: transparent;
    }
    .pagenator div::selection,
    .pagenator a::selection {
        background: transparent;
    }

    .pagenator svg {
        width: 0.75rem;
        height: 0.75rem;
    }

    .post-card-readmore span::selection {
        background: transparent;
    }
    .post-card-readmore a:hover {
        text-decoration: underline;
    }

    .post-nav {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        list-style: none;
        padding: 0;
        font-size: 1.125em;
    }
    .post-nav ::selection {
        background: transparent;
    }
    .post-nav a {
        text-decoration: none;
        color: inherit;
        display: flex;
        align-items: baseline;
    }
    .post-nav svg {
        width: 0.8125rem;
        height: 0.8125rem;
        fill: var(--color-font);
    }
    .post-nav-prev {
        margin-left: 1.125rem;
    }
    .post-nav-next {
        margin-right: 1.125rem;
    }
    .post-nav-prev:hover,
    .post-nav-next:hover {
        color: var(--color-purple);
    }

    article .tag-container {
        text-align: right;
    }

    article .tag-container a {
        text-decoration: none;
        color: inherit;
        font-size: 0.875em;
    }

    @media(max-width: 900px) {
        main {
            /* padding: 0 30px; */
        }
    }

    @media(max-width: 600px) {

    }
`

export default GlobalStyle;