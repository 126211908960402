import React from "react"

import Navbar from "./Navbar"
import Footer from "./Footer"
import GlobalStyle from "../styles/globalStyles"
import Normalize from "../styles/normalize"
import VarStyles from "../styles/vars"

const Layout = (props) => {
  const {children} = props;

  return (
    <>
      <VarStyles />
      <Normalize />
      <GlobalStyle />
      <div className="site-container">
        <Navbar />
        <main>{children}</main>
        <Footer />
      </div>
    </>
  );
}

export default Layout;
